// React
import React, { useContext, useEffect, useState } from 'react';

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Kendo
import { Loader as KendoLoader } from "@progress/kendo-react-indicators";

// Components
import Sidebar from '../../components/Sidebar/Sidebar';
import DnD from '../../components/DragAndDrop/DnD';

// Styles
import './Dashboard.scss';

const Dashboard = () => {
    const { currentModule, setCurrentModule } = useContext(GlobalContext);
    const [ weatherData, setWeatherData ] = useState(null);
    const day_or_night = ( (new Date().getHours()) > 18 || (new Date().getHours()) < 7 ) ? 'n' : 'd';

    useEffect(()=> {
        if (!currentModule || currentModule.id !== 'dashboard') {
            setCurrentModule({
                id: 'dashboard',
                name: 'Dashboard'
            });
        }

        fetch(process.env.REACT_APP_API_BASE_URL + '/weather')
            .then((response) => response.json())
            .then((response) => {
                setWeatherData(response.data.weather);
            })
            .catch((err) => {
            });
    }, [ currentModule, setCurrentModule, setWeatherData ]);

    return (
        <>
            <Sidebar>
                <div className="module-label dashboard">
                    { weatherData
                        ?
                        <div className="weather">
                            <div className="city">{ weatherData.city_name }</div>
                            <div className="icon">
                                <img src={ "/img/weather/icons/" + weatherData.current_weather.description.icon + day_or_night + ".svg" } alt={ weatherData.current_weather.description.text } />
                            </div>
                            <div className="temperature">{ weatherData.current_weather.temperature } °C</div>
                            <div className="description">{ weatherData.current_weather.description.text }</div>
                        </div>
                        :
                        <div className="k-h-full k-d-flex k-justify-content-center k-align-items-center">
                            <KendoLoader size="large" type="infinite-spinner" themeColor="light" />
                        </div>
                    }
                </div>
            </Sidebar>
            <div className="page">
                <div id="dashboard-icons">
                    {/* [...Array(30).keys()].map((i) =>
                        <div key={ i } className="square"></div>
                    ) */}
                    <DnD />
                </div>
            </div>
        </>
    );
}

export default Dashboard;
