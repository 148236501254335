// React
import React, { useContext, useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Components
import Sidebar from '../../components/Sidebar/Sidebar';

// Pages
import IssuesList from './pages/List';
import IssuePage from './pages/Issue';
import ExportPage from './pages/Export';
import ZonesPage from './pages/Zones';

const IssuesModule = () => {
    const { currentModule, setCurrentModule } = useContext(GlobalContext);

    useEffect(()=> {
        if (!currentModule || currentModule.id !== 'issues') {
            setCurrentModule({
                id: 'issues',
                name: 'Segnalazioni'
            });
        }
    }, [ currentModule, setCurrentModule ]);

    return (
        <>
            <Sidebar label={ currentModule?.name }>
                <div className="module-menu">
                    <ul className="section">
                        <li>Gestione Segnalazioni</li>
                        <ul>
                            <li>
                                <NavLink to="/issues" end>Lista segnalazioni</NavLink>
                            </li>
                            <li>
                                <NavLink to="/issues/export" end>Esporta</NavLink>
                            </li>
                            <li>
                                <NavLink to="/issues/zones" end>Zone</NavLink>
                            </li>
                        </ul>
                    </ul>
                </div>
            </Sidebar>

            <div className="page">
                <Routes>
                    <Route path="" element={ <IssuesList /> } />
                    <Route path="/:issueId" element={ <IssuePage /> } />
                    <Route path="/export" element={ <ExportPage /> } />
                    <Route path="/zones" element={ <ZonesPage /> } />
                </Routes>
            </div>
        </>
    );
}

export default IssuesModule;
