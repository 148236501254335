// React
import React, { useContext, useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Components
import Sidebar from '../../components/Sidebar/Sidebar';

// Pages
import MainCersPage from './pages/Main';

const CersModule = () => {
    const { currentModule, setCurrentModule } = useContext(GlobalContext);

    useEffect(()=> {
        if (!currentModule || currentModule.id !== 'cers') {
            setCurrentModule({
                id: 'cers',
                name: 'Cers'
            });
        }
    }, [ currentModule, setCurrentModule ]);

    return (
        <>
            <Sidebar label={ currentModule?.name }>
                <div className="module-menu">
                    <ul className="section">
                        <li>Gestione alberi</li>
                        <ul>
                            <li>
                                <NavLink to="/cers" end>Comunità energetica</NavLink>
                            </li>
                            <li>
                                <NavLink to="/cers/plants" end>Lista impianti</NavLink>
                            </li>
                        </ul>
                    </ul>
                </div>
            </Sidebar>

            <div className="page">
                <Routes>
                    <Route path="" element={ <MainCersPage /> } />
                </Routes>
            </div>
        </>
    );
}

export default CersModule;
