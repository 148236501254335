// React
import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Kendo
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

// Components
import Title from '../../../components/Page/Title';
import { default as YaardField } from '../../../components/Page/Field';
import Success from '../../../components/Page/Success';
import Loader from '../../../components/Loader/Loader';

const TreePage = () => {
    const navigate = useNavigate();
    const { treeId } = useParams();
    const { user } = useContext(GlobalContext);
    const [ isLoading, showLoader ] = useState(false);
    const [ tree, setTree ] = useState(null);
    const [ markerMoved, setMarkerMoved ] = useState(false);
    const [ response, setResponse ] = useState(null);
    const [ deleteConfirmation, showDeleteConfirmation ] = useState(false);
    const markerRef = useRef(null)

    useEffect(()=> {
        showLoader(true);
        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/tree/" + treeId, {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
            .then((response) => response.json())
            .then((response) => {
                showLoader(false);
                setTree(response.data.tree);
            });
    }, [ user, treeId, setTree ])

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    const new_coordinates = marker.getLatLng();
                    let data = { ...tree }
                    data.lat = new_coordinates.lat;
                    data.lon = new_coordinates.lng;
                    setTree(data);
                    setMarkerMoved(true);
                }
            },
        }),
        [ tree ],
    )

    const markerIcon = new L.Icon({
        iconUrl: require("../../../assets/icons/pin_tree.svg").default,
        iconSize: [ 24, 28 ],
        iconAnchor: [ 12, 28 ]
    });

    const updateTree = (data) => {
        let payload = { ...data };

        showLoader(true);

        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/tree/' + tree.id, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            },
            body: JSON.stringify(payload)
        })
        .then((response) => response.json())
        .then((response) => {
            showLoader(false);
            setResponse(response.status);
        })
        .catch((err) => {
        });
    }

    const deleteTree = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/tree/' + tree.id, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            }
        })
        .then((response) => response.json())
        .then((response) => {
            setResponse(response.status);
            showDeleteConfirmation(false);
            navigate("/trees");
        })
        .catch((err) => {
        });
    }

    return (
        <>
            { tree &&
                <>
                    <Title>{ tree.species.common_name } ({ tree.scope })</Title>
                    <div className="section grid-template-two-columns">
                        <div className="left">
                            <Form
                                ignoreModified={ true }
                                key={ JSON.stringify(tree) }
                                initialValues={ tree }
                                onSubmit={ updateTree }
                                render={(formRenderProps) => (
                                    <FormElement>
                                        <GridLayout cols={[ { width: '1fr' }, { width: '1fr' }, { width: '1fr' }, { width: '1fr' } ]} gap={{ rows: 20, cols: 20 }}>
                                            <GridLayoutItem row={ 1 } col={ 1 } colSpan={ 1 }>
                                                <fieldset className="k-form-fieldset">
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Etichetta</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field name="label" component={ Input } />
                                                        </div>
                                                    </FieldWrapper>
                                                </fieldset>
                                            </GridLayoutItem>

                                            <GridLayoutItem row={ 1 } col={ 4 } colSpan={ 1 }>
                                                <fieldset className="k-form-fieldset">
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Latitudine</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field name="lat" component={ Input } disabled />
                                                        </div>
                                                    </FieldWrapper>
                                                </fieldset>
                                            </GridLayoutItem>

                                            <GridLayoutItem row={ 2 } col={ 4 } colSpan={ 1 }>
                                                <fieldset className="k-form-fieldset">
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Longitudine</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field name="lon" component={ Input } disabled />
                                                        </div>
                                                    </FieldWrapper>
                                                </fieldset>
                                            </GridLayoutItem>

                                            <GridLayoutItem colSpan={ 4 }>
                                                <div className="k-form-buttons k-justify-content-between k-flex-wrap">
                                                    <Button type="submit" rounded={ null } size="large" themeColor="primary" disabled={ !formRenderProps.modified && !markerMoved }>SALVA</Button>
                                                    <Button rounded={ null } size="small" themeColor="error" onClick={ () => showDeleteConfirmation(true) }>CANCELLA</Button>
                                                </div>
                                            </GridLayoutItem>
                                        </GridLayout>
                                    </FormElement>
                                )}
                            />
                        </div>

                        <div className="right">
                            <YaardField label="Mappa" className="k-h-full">
                                <MapContainer center={[ tree.lat, tree.lon ]} zoom={ 18 } scrollWheelZoom={ true } zoomControl={ false }>
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                                    { tree &&
                                        <Marker
                                            draggable={ true }
                                            eventHandlers={ eventHandlers }
                                            position={[ tree.lat, tree.lon ]}
                                            ref={ markerRef }
                                            icon={ markerIcon }
                                        >
                                        </Marker>
                                    }
                                </MapContainer>
                            </YaardField>
                        </div>
                    </div>
                </>
            }

            <Success enter={ response } exit={ setResponse } />

            { deleteConfirmation && (
                <Dialog onClose={ () => showDeleteConfirmation(false) }>
                    <p style={{ margin: "25px", textAlign: "center" }} >
                        Vuoi cancellare questo albero?
                    </p>
                    <DialogActionsBar>
                        <Button rounded={ null } size="large" themeColor="primary" onClick={ () => showDeleteConfirmation(false) }>ANNULLA</Button>
                        <Button rounded={ null } size="large" themeColor="error" onClick={ () => deleteTree() }>CANCELLA</Button>
                    </DialogActionsBar>
                </Dialog>
            )}

            { isLoading && (<Loader />) }
        </>
    );
}

export default TreePage;
