// React
import React, { useState, useContext, useEffect } from 'react';

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Kendo
import { Chip } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { orderBy } from "@progress/kendo-data-query";
import Datetime from '../../../components/Grid/Cell/Datetime';

// Components
import Title from '../../../components/Page/Title';

const UsersList = () => {
    const { user, setUser } = useContext(GlobalContext);
    const [ users, setUsers ] = useState([]);
    const [ sort, setSort ] = useState([
        {
            field: "id",
            dir: "desc",
        },
    ]);

    useEffect(()=> {
        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/users", {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setUsers(response.data.users);
            });
    }, [ user, setUsers ])

    const Status = (props) => {
        let label = '-';
        let themeColor = 'base';
        switch (props.dataItem.status) {
            case 'active':
                label = 'Attivo';
                themeColor = 'success';
                break;

            case 'pending':
                label = 'In attesa';
                themeColor = 'warning';
                break;

            default:
                label = '-';
                themeColor = 'base';
        }

        return (
            <td style={{ textAlign: 'center' }}>
                <Chip text={ label } themeColor={ themeColor } rounded={ null } />
            </td>
        )
    }

    const isAdmin = (props) => {
        if (props.dataItem.is_admin) {
            return (
                <td style={{ textAlign: 'center' }}>
                    <span className="k-icon k-i-check" style={{ color: 'green' }}></span>
                </td>
            );
        }
        else {
            return (
                <td></td>
            )
        }
    }

    return (
        <>
            <Title>Lista utenti <small>({ users.length })</small></Title>
            <div className="section">
                <Grid
                    data={ orderBy(users, sort) }
                    scrollable="none"
                    sortable={ true }
                    sort={ sort }
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                >
                    <GridColumn field="id" title="Id" width="40px" />
                    <GridColumn field="email" title="Email" />
                    <GridColumn field="first_name" title="Nome" />
                    <GridColumn field="last_name" title="Cognome" />
                    <GridColumn field="issues_count" title="Segnalazioni" />
                    <GridColumn field="status" title="Stato" cell={ Status } />
                    <GridColumn field="is_admin" title="Admin?" cell={ isAdmin } />
                    <GridColumn field="activated_at" title="Attivato il" cell={ Datetime }/>
                </Grid>
            </div>
        </>
    );
}

export default UsersList;
