// React
import React from 'react'

// Kendo
import { Loader as KendoLoader } from "@progress/kendo-react-indicators";

// Styles
import "./Loader.scss";

export const Loader = (props) => {
    return (
        <div id="loader">
            <KendoLoader size="large" type="infinite-spinner" />
        </div>
    )
}

export default Loader;
