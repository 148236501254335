// React
import React from 'react'

export default function Images(props) {
    let photos = '-';
    if (props.dataItem.photos && props.dataItem.photos.length > 0) {
        photos = [];
        props.dataItem.photos.forEach((photo) => {
            photos.push(
                <img key={ photo.id } src={ photo.url } alt="" style={{ maxHeight: '60px' }}/>
            )
        });
    }

    return (
        <td>{ photos }</td>
    );
}
