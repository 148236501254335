// React
import React, { useContext, useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Components
import Sidebar from '../../components/Sidebar/Sidebar';

// Pages
import TreesList from './pages/List';
import TreePage from './pages/Tree';
import TreesSpeciesList from './pages/Species';

const TreesModule = () => {
    const { currentModule, setCurrentModule } = useContext(GlobalContext);

    useEffect(()=> {
        if (!currentModule || currentModule.id !== 'trees') {
            setCurrentModule({
                id: 'trees',
                name: 'Alberi'
            });
        }
    }, [ currentModule, setCurrentModule ]);

    return (
        <>
            <Sidebar label={ currentModule?.name }>
                <div className="module-menu">
                    <ul className="section">
                        <li>Gestione alberi</li>
                        <ul>
                            <li>
                                <NavLink to="/trees" end>Lista alberi</NavLink>
                            </li>
                            <li>
                                <NavLink to="/trees/species" end>Lista specie</NavLink>
                            </li>
                        </ul>
                    </ul>
                </div>
            </Sidebar>

            <div className="page">
                <Routes>
                    <Route path="" element={ <TreesList /> } />
                    <Route path="/:treeId" element={ <TreePage /> } />
                    <Route path="/species" element={ <TreesSpeciesList /> } />
                </Routes>
            </div>
        </>
    );
}

export default TreesModule;
