// React
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Leaflet
import L from "leaflet";

// Kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

// Components
import Title from '../../../components/Page/Title';
import Field from '../../../components/Page/Field';
import { DropdownFilterCell } from "../../../components/Grid/Filter/Dropdown";

// Loader
import { TreesLoader } from "../odata/TreesLoader";

const TreesList = () => {
    const mapRef = useRef();
    const navigate = useNavigate();
    const { user } = useContext(GlobalContext);
    const [ markers, setMarkers ] = useState([]);
    const [ trees, setTrees ] = useState({
        data: [],
        total: 0,
    });
    const [ species, setSpecies ] = useState([]);
    const [ scopes, setScopes ] = useState([]);

    const initialDataState = {
        take: 15,
        skip: 0
    };
    const [ dataState, setDataState ] = useState(initialDataState);
    let map = useRef(undefined);

    useEffect(()=> {
        if (!map.current) {
            map.current = L.map(mapRef.current, {
                center: [ 45.3140617, 9.505 ],
                preferCanvas: true,
                zoom: 15
            });

            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
            }).addTo(map.current);
        }

        if (markers.length === 0) {
            fetch(process.env.REACT_APP_API_BASE_URL + "/admin/trees/map", {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    setMarkers(response.data.markers);
                    for (let marker of response.data.markers) {
                        L.circleMarker([ marker.lat, marker.lon ], {
                            color: '#006400',
                            radius: 5,
                            weight: 1,
                            fillOpacity: 1,
                            fillColor: '#568203'
                        }).addTo(map.current);
                    }
            });
        }

        if (species.length === 0) {
            fetch(process.env.REACT_APP_API_BASE_URL + "/admin/trees/species", {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    setSpecies(response.data.species);
            });
        }

        if (scopes.length === 0) {
            fetch(process.env.REACT_APP_API_BASE_URL + "/admin/trees/scopes", {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    setScopes(response.data.scopes);
            });
        }
    }, [ user, markers, setMarkers, map, species, setSpecies, scopes, setScopes ])

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const dataReceived = (trees) => {
        if (trees.data) {
            setTrees(trees);
        }
        else {
            setTrees({
                data: [],
                total: 0,
            });
        }
    };

    const Coordinates = (props) => {
        let coordinates = '-';
        if (props.dataItem.lat && props.dataItem.lon) {
            coordinates = (
                <>
                    Lat: { props.dataItem.lat.toFixed(5) }<br />Lon: { props.dataItem.lon.toFixed(5) }
                </>
            );
        }
        return (
            <td style={{ whiteSpace: 'nowrap' }}>{ coordinates }</td>
        );
    }

    const ScopesFilterCell = (props) => {
        if (scopes) {
            return (
                <DropdownFilterCell
                    { ...props }
                    labels={ scopes }
                    data={ scopes }
                />
            )
        }
    };

    const SpeciesFilterCell = (props) => {
        if (species) {
            return (
                <DropdownFilterCell
                    { ...props }
                    labels={ species.map(x => x.common_name) }
                    data={ species.map(x => x.id) }
                />
            )
        }
    };

    const Actions = (props) => {
        return (
            <td>
                <div className="k-d-flex k-gap-sm k-justify-items-center">
                    <Button className="buttons-container-button" icon="hyperlink-open" onClick={ () => openTree(props.dataItem.id) }></Button>
                    <Button className="buttons-container-button" icon="search" onClick={ () => highlightTree(props.dataItem.id) }></Button>
                </div>
            </td>
        )
    }

    const openTree = (tree_id) => {
        navigate("/trees/" + tree_id);
    }

    const highlightTree = (tree_id) => {
        console.log(tree_id);
    }

    return (
        <>
            <Title>Lista alberi <small>({ trees.total })</small></Title>
            <div className="section grid-template-two-columns">
                <div className="left k-pt-6">
                    <Grid
                        style={{
                            height: "100%",
                        }}
                        scrollable="scrollable"
                        fixedScroll={ true }
                        sortable={ true }
                        filterable={ true }
                        pageable={{
                            buttonCount: 5
                        }}
                        { ...dataState }
                        data={ trees }
                        onDataStateChange={ dataStateChange }
                    >
                        <GridColumn field="id" title="Id" width="80px" filterable={ false } />
                        <GridColumn field="label" title="Etichetta" width="80px" filterable={ false } />
                        <GridColumn field="scope" title="Ambito" filterCell={ ScopesFilterCell } />
                        <GridColumn field="species.common_name" title="Specie" filterCell={ SpeciesFilterCell } />
                        <GridColumn field="coordinates" title="Coordinate" cell={ Coordinates } filterable={ false } />
                        <GridColumn field="actions" title="&nbsp;" width="100px" filterable={ false } cell={ Actions } />
                    </Grid>

                    <TreesLoader dataState={ dataState } onDataReceived={ dataReceived } />
                </div>

                <div className="right">
                    <Field label="Mappa" className="k-h-full">
                        <div ref={ mapRef } />
                    </Field>
                </div>
            </div>
        </>
    );
}

export default TreesList;
