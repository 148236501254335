// React
import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Kendo
import { Button, Chip } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

// date-fns
import { format, parseISO, formatDistanceToNow  } from 'date-fns'

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

// Components
import Title from '../../../components/Page/Title';
import Field from '../../../components/Page/Field';
import Success from '../../../components/Page/Success';
import Loader from '../../../components/Loader/Loader';

// Area Selector
// import { AreaSelector, IArea } from '@bmunozg/react-image-area'

// Styles
import './Issue.scss';

const IssuePage = () => {
    const navigate = useNavigate();
    const { issueId } = useParams();
    const { user } = useContext(GlobalContext);
    const [ isLoading, showLoader ] = useState(false);
    const [ issue, setIssue ] = useState(null);
    const [ photo, showPhoto ] = useState(null);
    const [ photosRerender, reRenderPhotos ] = useState(0);
    const [ response, setResponse ] = useState(null);
    const [ areas, setAreas ] = useState([]);
    const [ isMarkerDraggable, setMarkerDraggable ] = useState(false);
    const [ deleteConfirmation, showDeleteConfirmation ] = useState(false);
    const [ restorePhotoConfirmation, showRestorePhotoConfirmation ] = useState(false);
    const selectedPhoto = useRef(null);
    const markerRef = useRef(null)

    // const onChangeHandler = (areas: IArea[]) => {
    //     setAreas(areas);
    // }

    useEffect(()=> {
        showLoader(true);
        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/issue/" + issueId, {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
            .then((response) => response.json())
            .then((response) => {
                showLoader(false);
                setIssue(response.data.issue);
            });
    }, [ user, issueId, setIssue ])

    const markerIcon = new L.divIcon({
        html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>',
        iconSize: [ 40, 47 ],
        iconAnchor: [ 20, 47 ],
        className: isMarkerDraggable ? 'shake' : null
    });

    const issueStatuses = [
        { label: 'In attesa di moderazione', value: 'pending' },
        { label: 'Approvata', value: 'approved' },
        { label: 'Rifiutata', value: 'rejected' }
    ];

    const saveIssue = (e) => {
        setMarkerDraggable(false);
        showLoader(true);
        e.preventDefault();
        let payload = {
            status: issue.status,
            lat: issue.lat,
            lon: issue.lon
        };

        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/issue/' + issue.id, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            },
            body: JSON.stringify(payload)
        })
        .then((response) => response.json())
        .then((response) => {
            showLoader(false);
            setResponse(response.status);
        })
        .catch((err) => {
        });
    }

    const openImage = (photo) => {
        showPhoto(photo);
    }

    const savePhoto = () => {
        showLoader(true);

        let payload = {
            photo_id: photo.id,
            areas: []
        };
        const ratio =  selectedPhoto.current.naturalWidth / selectedPhoto.current.offsetWidth;
        areas.forEach((area) => {
            payload.areas.push({
                x: area.x * ratio,
                y: area.y * ratio,
                width: area.width * ratio,
                height: area.height * ratio
            })
        });

        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/photo/obfuscate', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            },
            body: JSON.stringify(payload)
        })
        .then((response) => response.json())
        .then((response) => {
            showLoader(false);
            let newIssue = { ...issue };
            let editedPhotoIndex = newIssue.photos.findIndex((p) => p.id === response.data.photo.id);
            newIssue.photos[editedPhotoIndex] = response.data.photo;
            setIssue(newIssue);
            reRenderPhotos(photosRerender + 1);
            showPhoto(null);
        })
        .catch((err) => {
        });
    }

    const restorePhoto = () => {
        showLoader(true);

        let payload = {
            photo_id: photo.id,
        };

        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/photo/deobfuscate', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            },
            body: JSON.stringify(payload)
        })
        .then((response) => response.json())
        .then((response) => {
            showLoader(false);
            let newIssue = { ...issue };
            let editedPhotoIndex = newIssue.photos.findIndex((p) => p.id === response.data.photo.id);
            newIssue.photos[editedPhotoIndex] = response.data.photo;
            setIssue(newIssue);
            reRenderPhotos(photosRerender + 1);
            setAreas([]);
            showPhoto(null);
        })
        .catch((err) => {
        });
    }

    const removeArea = (areaProps) => {
        const newAreas = areas.filter((iten, index) => index !== (areaProps.areaNumber  -1 ));
        setAreas(newAreas);
    }

    const selectedAreaRender = (areaProps) => {
        if (!areaProps.isChanging) {
            return (
                <div className="selection" key={ areaProps.areaNumber }>
                    <div className="remove" onClick={ () => removeArea(areaProps) }>&times;</div>
                    { areaProps.areaNumber }
                </div>
            );
        }
    }

    const deleteIssue = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/issue/' + issue.id, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            }
        })
        .then((response) => response.json())
        .then((response) => {
            setResponse(response.status);
            showDeleteConfirmation(false);
            navigate("/issues");
        })
        .catch((err) => {
        });
    }

    const EmailList = ({ items }) => {
        let emails = [];
        items.forEach((item) => {
            let formattedDatetime = format(parseISO(item.sent_at), 'yyyy-LL-dd HH:mm:ss');
            let humanReadableTime = formatDistanceToNow(parseISO(item.sent_at), { addSuffix: true });
            let icon = null;
            let type = null;

            switch (item.type) {
                case 'issue_rejected':
                    icon = ( <span className="k-icon k-font-icon k-i-x-circle red"></span> );
                    type = 'Rifiutata';
                    break;

                default:
            }

            emails.push(
                <div className="email">
                    <div className="icon">{ icon }</div>
                    <div className="type">
                        { type }
                        { item.user_who_sent && (
                            <>
                                <br />
                                <small>(da { item.user_who_sent.full_name })</small>
                            </>
                        )}
                    </div>
                    <div className="date">{ formattedDatetime }<br /><small>({ humanReadableTime })</small></div>
                    <div className="link">
                        <a target="_blank" rel="noreferrer" href={ item.webview_url } alt="">
                            <span className="k-icon k-font-icon k-i-hyperlink-open-sm"></span>
                        </a>
                    </div>
                </div>
            );
        });

        return (
            <div className="emails">
                { emails }
            </div>
        )
    }

    const markerEvents = useMemo(
        () => ({
            dblclick() {
                setMarkerDraggable(!isMarkerDraggable);
            },
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setIssue({ ...issue, lat: marker.getLatLng().lat, lon: marker.getLatLng().lng })
                }
            },
        }),
        [ isMarkerDraggable, issue ],
    )

    return (
        <>
            <Title>Segnalazione</Title>
            { issue &&
                <div className="section grid-template-one">
                    <div className="top-left k-d-flex k-flex-col k-gap-5">
                        <Field label="Indirizzo" type="text" value={ issue.location_display_name } />

                        <Field label="Categorie">
                            { issue.categories.map((item, index) =>
                                <>
                                    <Chip key={ item.id } text={ item.name } rounded={ null } /><br />
                                </>
                            )}
                        </Field>

                        <Field label="Segnalato da">
                            { issue.user.first_name } { issue.user.last_name }
                            { issue.is_public ||
                                <span className="k-font-size-xs k-font-italic"> (Nome non visualizzato)</span>
                            }
                        </Field>

                        <Field label="Testo" type="text" multiline={ true } value={ issue.text } />
                    </div>

                    <div className="top-middle k-d-flex k-flex-col k-gap-5">
                        <Field label="Stato" name="status" type="select" items={ issueStatuses } value={ issue.status } onChange={(e) => setIssue({ ...issue, status: e.target.value.value }) } />

                        <div className="k-d-flex k-flex-row k-gap-5">
                            <Button rounded={ null } size="large" themeColor="primary" onClick={ saveIssue }>SALVA</Button>
                            <Button rounded={ null } size="large" themeColor="error" onClick={ () => showDeleteConfirmation(true) }>CANCELLA</Button>
                        </div>

                        <Field label="Email">
                            { issue.emails && issue.emails.length > 0 &&
                                <EmailList items={ issue.emails } />
                            }
                        </Field>
                    </div>

                    <div className="bottom-left">
                        <Field key={ photosRerender } label="Foto" type="images" items={ issue.photos } onImageClick={ openImage }/>
                    </div>

                    <div className="right">
                        <Field label="Mappa" className="k-h-full">
                            <MapContainer center={[ issue.lat, issue.lon ]} zoom={ 15 } scrollWheelZoom={ true } zoomControl={ false }>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                                { issue.lat && issue.lon &&
                                    <Marker
                                        ref={ markerRef }
                                        icon={ markerIcon }
                                        position={[ issue.lat, issue.lon ]}
                                        draggable={ isMarkerDraggable }
                                        eventHandlers={ markerEvents }
                                    ></Marker>
                                }
                            </MapContainer>
                        </Field>
                    </div>
                </div>
            }

            <Success enter={ response } exit={ setResponse } />

            { photo &&
                <div className="image-preview">
                    <div className="close" onClick={ () => showPhoto(null) }>&times;</div>
                    <div className="area-selector">
                        { /* photo.has_original === false &&
                            <AreaSelector
                                areas={ areas }
                                onChange={ onChangeHandler }
                                customAreaRenderer={ selectedAreaRender }
                                globalAreaStyle={{
                                    backgroundColor: 'black',
                                    opacity: 1
                                }}>
                                <img ref={ selectedPhoto } src={ photo.url_hi_res } alt="" />
                            </AreaSelector>
                            */
                        }
                        { photo.has_original &&
                            <img ref={ selectedPhoto } src={ photo.url_hi_res } alt="" />
                        }
                    </div>
                    <div className="tools">
                        { photo.has_original &&
                            <Button rounded={ null } size="large" themeColor="primary" onClick={ restorePhoto }>RIPRISTINA ORIGINALE</Button>
                        }
                        { photo.has_original === false &&
                            <Button rounded={ null } size="large" themeColor="primary" disabled={ areas.length <= 0 } onClick={ savePhoto }>SALVA</Button>
                        }
                    </div>
                </div>
            }

            { restorePhotoConfirmation && (
                <Dialog onClose={ () => showDeleteConfirmation(false) }>
                    <p style={{ margin: "25px", textAlign: "center" }} >
                        Vuoi cancellare questa segnalazione?
                    </p>
                    <DialogActionsBar>
                        <Button rounded={ null } size="large" themeColor="primary" onClick={ () => showDeleteConfirmation(false) }>ANNULLA</Button>
                        <Button rounded={ null } size="large" themeColor="error" onClick={ () => deleteIssue() }>CANCELLA</Button>
                    </DialogActionsBar>
                </Dialog>
            )}

            { deleteConfirmation && (
                <Dialog onClose={ () => showRestorePhotoConfirmation(false) }>
                    <p style={{ margin: "25px", textAlign: "center" }} >
                        Vuoi ripristinare la foto originale?
                    </p>
                    <DialogActionsBar>
                        <Button rounded={ null } size="large" themeColor="primary" onClick={ () => showDeleteConfirmation(false) }>ANNULLA</Button>
                        <Button rounded={ null } size="large" themeColor="error" onClick={ () => deleteIssue() }>CANCELLA</Button>
                    </DialogActionsBar>
                </Dialog>
            )}

            { isLoading && (<Loader />) }
        </>
    );
}

export default IssuePage;
