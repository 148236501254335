// React
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Styles
import './Topbar.scss';

const Topbar = () => {
    const { t } = useTranslation();
    const { user, setUser } = useContext(GlobalContext);

    const logout = () => {
        localStorage.removeItem('user');
        setUser(null);
    }

    return (
        <div id="topbar">
            <div className="logo k-flex-grow">
                <Link to="/">
                    <img src="/img/logo/logo.png" alt="Comune di Lodi"/>
                </Link>
            </div>
            <div className="breadcrumb k-flex-grow">
                <Link to="/dashboard">Laus.app</Link>
            </div>
            <div className="menu k-flex-grow k-text-right">
                <div>
                    <Link to="/users">{ t('users') }</Link>
                </div>
                <div>
                    <Link>{ t('devices') }</Link>
                </div>
                <div>
                    <Link to="/issues">{ t('issues') }</Link>
                </div>
            </div>
            <div className="profile k-flex-grow k-text-right">
                <div className="fullname">{ user.first_name } { user.last_name }</div>
                <div className="image"></div>
                <div className="dropdown">
                    <div className="item" onClick={ () => logout() }>Logout</div>
                </div>
            </div>
        </div>
    )
}

export default Topbar;
