// React
import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Kendo
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

// Components
import Title from '../../../components/Page/Title';
import { default as YaardField } from '../../../components/Page/Field';
import Success from '../../../components/Page/Success';
import Loader from '../../../components/Loader/Loader';

const ParkingPage = () => {
    const navigate = useNavigate();
    const { parkingId } = useParams();
    const { user } = useContext(GlobalContext);
    const [ isLoading, showLoader ] = useState(false);
    const [ parking, setParking ] = useState(null);
    const [ response, setResponse ] = useState(null);
    const [ deleteConfirmation, showDeleteConfirmation ] = useState(false);

    useEffect(()=> {
        showLoader(true);
        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/parking/" + parkingId, {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
            .then((response) => response.json())
            .then((response) => {
                showLoader(false);
                setParking(response.data.parking);
            });
    }, [ user, parkingId, setParking ])

    const markerIcon = new L.Icon({
        iconUrl: require("../../../assets/icons/pin_parking.svg").default,
        iconSize: [ 24, 28 ],
        iconAnchor: [ 12, 28 ]
    });

    const updateParking = (data) => {
        let payload = { ...data };
        payload.type = parkingTypes.find(i => i.label === payload.parking_type).value;
        delete payload.parking_type;

        showLoader(true);

        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/parking/' + parking.id, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            },
            body: JSON.stringify(payload)
        })
        .then((response) => response.json())
        .then((response) => {
            showLoader(false);
            setResponse(response.status);
        })
        .catch((err) => {
        });

    }

    const deleteParking = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/parking/' + parking.id, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            }
        })
        .then((response) => response.json())
        .then((response) => {
            setResponse(response.status);
            showDeleteConfirmation(false);
            navigate("/parkings");
        })
        .catch((err) => {
        });
    }

    const parkingTypes = [
        { label: 'A Raso', value: 'level' },
        { label: 'In Struttura', value: 'structure' }
    ]

    return (
        <>
            <Title>Parcheggio</Title>
            { parking &&
                <div className="section grid-template-two-columns">
                    <div className="left">
                        <Form
                            initialValues={{
                                ...parking,
                                parking_type: parkingTypes.find(i => i.value === parking.type).label
                            }}
                            onSubmit={ updateParking }
                            render={(formRenderProps) => (
                                <FormElement>
                                    <GridLayout cols={[ { width: '1fr' }, { width: '1fr' }, { width: '1fr' }, { width: '1fr' } ]} gap={{ rows: 20, cols: 20 }}>
                                        <GridLayoutItem row={ 1 } col={ 1 } colSpan={ 2 }>
                                            <fieldset className="k-form-fieldset">
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Nome</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field name="name" component={ Input } />
                                                    </div>
                                                </FieldWrapper>
                                            </fieldset>
                                        </GridLayoutItem>

                                        <GridLayoutItem row={ 1 } col={ 3 }>
                                            <fieldset className="k-form-fieldset">
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Posti</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field name="spaces" component={ NumericTextBox } />
                                                    </div>
                                                </FieldWrapper>
                                            </fieldset>
                                        </GridLayoutItem>

                                        <GridLayoutItem row={ 1 } col={ 4 }>
                                            <fieldset className="k-form-fieldset">
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Tipo</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name="parking_type"
                                                            data={ parkingTypes.map(i => i.label) }
                                                            component={ DropDownList }
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                            </fieldset>
                                        </GridLayoutItem>

                                        <GridLayoutItem row={ 2 } col={ 1 } colSpan={ 2 }>
                                            <fieldset className="k-form-fieldset">
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Orari</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field name="time_table" autoSize={ true } component={ TextArea } />
                                                    </div>
                                                </FieldWrapper>
                                            </fieldset>
                                        </GridLayoutItem>

                                        <GridLayoutItem row={ 2 } col={ 3 } colSpan={ 2 }>
                                            <fieldset className="k-form-fieldset">
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Tariffe</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field name="fees" autoSize={ true } component={ TextArea } />
                                                    </div>
                                                </FieldWrapper>
                                            </fieldset>
                                        </GridLayoutItem>

                                        <GridLayoutItem colSpan={ 4 }>
                                            <div className="k-form-buttons k-justify-content-between k-flex-wrap">
                                                <Button type="submit" rounded={ null } size="large" themeColor="primary" disabled={ !formRenderProps.modified }>SALVA</Button>
                                                <Button rounded={ null } size="small" themeColor="error" onClick={ () => showDeleteConfirmation(true) }>CANCELLA</Button>
                                            </div>
                                        </GridLayoutItem>
                                    </GridLayout>
                                </FormElement>
                            )}
                        />
                    </div>

                    <div className="right">
                        <YaardField label="Mappa" className="k-h-full">
                            <MapContainer center={[ parking.lat, parking.lon ]} zoom={ 15 } scrollWheelZoom={ true } zoomControl={ false }>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                                { parking &&
                                    <Marker
                                        position={[ parking.lat, parking.lon ]}
                                        icon={ markerIcon }
                                    >
                                    </Marker>
                                }
                            </MapContainer>
                        </YaardField>
                    </div>
                </div>
            }

            <Success enter={ response } exit={ setResponse } />

            { deleteConfirmation && (
                <Dialog onClose={ () => showDeleteConfirmation(false) }>
                    <p style={{ margin: "25px", textAlign: "center" }} >
                        Vuoi cancellare questo parcheggio?
                    </p>
                    <DialogActionsBar>
                        <Button rounded={ null } size="large" themeColor="primary" onClick={ () => showDeleteConfirmation(false) }>ANNULLA</Button>
                        <Button rounded={ null } size="large" themeColor="error" onClick={ () => deleteParking() }>CANCELLA</Button>
                    </DialogActionsBar>
                </Dialog>
            )}

            { isLoading && (<Loader />) }
        </>
    );
}

export default ParkingPage;
