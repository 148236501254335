// React
import React from 'react';

// Kendor
import { Chip } from "@progress/kendo-react-buttons";

export default function Chips(props) {
    let pills = [];
    let items = [];

    if (props.items) {
        items = props.items;
    }
    else if (props.dataItem[props.field]) {
        items = props.dataItem[props.field];
    }

    items.forEach((item) => {
        pills.push(
            <Chip key={ item.id } text={ item.name } rounded={ null } />
        );
    });

    return (
        <td className="pills">
            { pills }
        </td>
    )
};
