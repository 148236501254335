// React
import React from 'react';

// date-fns
import { format, parseISO, formatDistanceToNow  } from 'date-fns'

export default function Datetime(props) {
    let formattedDatetime = '-';
    let humanReadableTime = null;
    if (props.dataItem[props.field]) {
        formattedDatetime = format(parseISO(props.dataItem[props.field]), 'yyyy-LL-dd HH:mm:ss');
        humanReadableTime = formatDistanceToNow(parseISO(props.dataItem[props.field]), { addSuffix: true });
    }

    return (
        <td>
            { formattedDatetime }
            { humanReadableTime &&
                <>
                    <br/>
                    <small>({ humanReadableTime })</small>
                </>
            }
        </td>
    )
};
