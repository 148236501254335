// React
import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

// Global Context
import { GlobalContext } from './contexts/Global';

// Components
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

// Pages
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';

// Modules
import UsersModule from './modules/users/Users';
import IssuesModule from './modules/issues/Issues';
import ParkingsModule from './modules/parkings/Parkings';
import TreesModule from './modules/trees/Trees';
import CleaningModule from './modules/cleaning/Cleaning';
import EventsModule from './modules/events/Events';
import CersModule from './modules/cers/Cers';

// Styles
import './App.scss';

const App = () => {
    const { user, setUser } = useContext(GlobalContext);
    let redirectTo = '/dashboard';

    useEffect(()=> {
        if (!user) {
            const userData = localStorage.getItem("user");
            if (!user && userData) {
                setUser(JSON.parse(userData));
                redirectTo = window.location.pathname;
            }
        }
    }, [ user, setUser, redirectTo ])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ user ? <Navigate to={ redirectTo } /> : <Login /> } />
                <Route path="/dashboard" element={ <ProtectedRoute><Dashboard /></ProtectedRoute> } />
                <Route path="/users" element={ <ProtectedRoute><UsersModule /></ProtectedRoute> } />
                <Route path="/issues/*" element={ <ProtectedRoute><IssuesModule /></ProtectedRoute> } />
                <Route path="/parkings/*" element={ <ProtectedRoute><ParkingsModule /></ProtectedRoute> } />
                <Route path="/trees/*" element={ <ProtectedRoute><TreesModule /></ProtectedRoute> } />
                <Route path="/cleaning/*" element={ <ProtectedRoute><CleaningModule /></ProtectedRoute> } />
                <Route path="/events/*" element={ <ProtectedRoute><EventsModule /></ProtectedRoute> } />
                <Route path="/cers/*" element={ <ProtectedRoute><CersModule /></ProtectedRoute> } />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
