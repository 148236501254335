// React
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

// Kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import Datetime from '../../../components/Grid/Cell/Datetime';
import Chips from '../../../components/Grid/Cell/Chips';
import Images from '../../../components/Grid/Cell/Images';

// Components
import Title from '../../../components/Page/Title';
import Field from '../../../components/Page/Field';
import { DropdownFilterCell } from "../../../components/Grid/Filter/Dropdown";

const ParkingsList = () => {
    const navigate = useNavigate();
    const { user } = useContext(GlobalContext);
    const [ parkings, setParkings ] = useState([]);

    const initialDataState = {
        filter: undefined,
        sort: [
            {
                field: "spaces",
                dir: "desc"
            }
        ]
    };
    const [ dataState, setDataState ] = useState(initialDataState);
    const [ dataResult, setDataResult ] = useState(
        process(parkings, dataState)
    );

    const markerIcon = new L.Icon({
        iconUrl: require("../../../assets/icons/pin_parking.svg").default,
        iconSize: [ 24, 28 ],
        iconAnchor: [ 12, 28 ]
    });

    useEffect(()=> {
        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/parkings", {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setParkings(response.data.parkings);
                setDataResult(process(response.data.parkings, dataState));
    });
    }, [ user, setParkings, dataState ])

    const Coordinates = (props) => {
        let coordinates = '-';
        if (props.dataItem.lat && props.dataItem.lon) {
            coordinates = (
                <>
                    Lat: { props.dataItem.lat.toFixed(5) }<br />Lon: { props.dataItem.lon.toFixed(5) }
                </>
            );
        }
        return (
            <td style={{ whiteSpace: 'nowrap' }}>{ coordinates }</td>
        );
    }

    const Type = (props) => {
        let label = '-';
        let themeColor = 'base';
        switch (props.dataItem.type) {
            case 'level':
                label = 'A raso';
                break;

            case 'structure':
                label = 'In struttura';
                break;

            default:
                label = '-';
                themeColor = 'base';
        }

        return (
            <td><Chip text={ label } themeColor={ themeColor } rounded={ null } /></td>
        )
    }

    const Actions = (props) => {
        return (
            <td>
                <Button className="buttons-container-button" icon="hyperlink-open" onClick={ () => openParking(props.dataItem.id) }></Button>
            </td>
        )
    }

    const openParking = (parking_id) => {
        navigate("/parkings/" + parking_id);
    }

    return (
        <>
            <Title>Lista parcheggi <small>({ parkings.length })</small></Title>
            <div className="section grid-template-two-columns">
                <div className="left">
                    <Grid
                        style={{
                            height: "100%",
                        }}
                        className="k-mt-6"
                        scrollable={ true }
                        fixedScroll={ true }
                        sortable={ true }
                        filterable={ false }
                        data={dataResult}
                        {...dataState}
                        onDataStateChange={(e) => {
                            setDataState(e.dataState);
                            setDataResult(process(parkings, e.dataState));
                        }}
                    >
                        <GridColumn field="id" title="Id" width="40px" />
                        <GridColumn field="name" title="Nome" />
                        <GridColumn field="type" title="Tipo" cell={ Type } />
                        <GridColumn field="spaces" title="Posti" width="60px" />
                        <GridColumn field="coordinates" title="Coordinate" cell={ Coordinates } />
                        <GridColumn field="actions" title="&nbsp;" width="60px" filterable={ false } cell={ Actions } />
                    </Grid>
                </div>

                <div className="right">
                    <Field label="Mappa" className="k-h-full">
                        <MapContainer center={[ 45.3140617, 9.505 ]} zoom={ 15 } scrollWheelZoom={ true } zoomControl={ false }>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                            { parkings.length > 0 && parkings.map((parking, index) =>
                                <Marker
                                    key={ `marker-${index}` }
                                    position={[ parking.lat, parking.lon ]}
                                    icon={ markerIcon }
                                >
                                </Marker>
                            )}
                        </MapContainer>
                    </Field>
                </div>
            </div>
        </>
    );
}

export default ParkingsList;
