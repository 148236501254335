// React
import React from 'react';

// Leaflet
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';

// Components
import Title from '../../../components/Page/Title';
import Field from '../../../components/Page/Field';

// Styles

const MainCersPage = () => {
    return (
        <>
            <Title>Comunità energetica lodigiana</Title>
            <div className="section grid-template-two k-h-full">
                <div className="left">

                </div>

                <div className="right">
                    <Field label="Mappa" className="k-h-full">
                        <MapContainer center={[ 45.3140617, 9.4998361 ]} zoom={ 13 } scrollWheelZoom={ true } zoomControl={ false }>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        </MapContainer>
                    </Field>
                </div>
            </div>
        </>
    );
}

export default MainCersPage;
