// React
import React, { useState, useCallback } from 'react'

// Kendo
import { DragAndDrop } from "@progress/kendo-react-common";
import { DraggableIcon } from "./DraggableIcon";
import { DroppableBox } from "./DroppableBox";

const DnD = () => {
    const [ modules, setModulesPosition ] = useState([
        {
            'id': 'issues',
            'label': 'Segnalazioni'
        },
        {
            'id': 'trees',
            'label': 'Alberi'
        },
        {
            'id': 'parkings',
            'label': 'Parcheggi'
        },
        {
            'id': 'cleaning',
            'label': 'Igiene Urbana'
        },
        {
            'id': 'events',
            'label': 'Eventi'
        },
        {
            'id': 'cers',
            'label': 'Comunità energetica'
        },
        // {
        //     'id': 'pharmacies',
        //     'label': 'Farmacie'
        // },
        // false,
        // false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ]);

    const handleDrop = useCallback((id, children) => {
    }, []);

    return (
        <DragAndDrop>
            { [...Array(30).keys()].map((i) =>
                <DroppableBox key={ "square" + i } selected={ modules[i] === false ? false : true } id={ i } onDrop={ handleDrop }>
                    { modules[i] ?
                        <DraggableIcon icon={ modules[i].id } label={ modules[i].label } />
                    : null}
                </DroppableBox>
            )}
        </DragAndDrop>
    );
};

export default DnD;
