// React
import React, { useContext, useRef } from 'react';
import * as ReactDOM from "react-dom";

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Kendor
import { toODataString } from "@progress/kendo-data-query";

export const TreesLoader = (props) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL + "/admin/odata/trees?";
    const { user } = useContext(GlobalContext);

    const init = {
        method: "GET",
        accept: "application/json",
        headers: {
            Authorization: 'Bearer ' + user.access_token
        },
    };
    const lastSuccess = useRef("");
    const pending = useRef("");
    const requestDataIfNeeded = () => {
        console.log(toODataString(props.dataState));
        if (pending.current || toODataString(props.dataState) === lastSuccess.current) {
            return;
        }

        pending.current = toODataString(props.dataState);
        fetch(baseUrl + pending.current, init)
            .then((response) => response.json())
            .then((json) => {
                lastSuccess.current = pending.current;
                pending.current = "";
                if (toODataString(props.dataState) === lastSuccess.current) {
                    props.onDataReceived.call(undefined, {
                        data: json.data.trees,
                        total: json.data.total,
                    });
                }
                else {
                    requestDataIfNeeded();
                }
            });
    };

    requestDataIfNeeded();
    return pending.current ? <LoadingPanel /> : null;
};

const LoadingPanel = () => {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
        </div>
    );
    const gridContent = document && document.querySelector(".k-grid-content");
    return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
};
