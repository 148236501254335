// React
import React, { useState, useContext, useEffect } from 'react';

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { process } from "@progress/kendo-data-query";

// Components
import Title from '../../../components/Page/Title';
import Success from '../../../components/Page/Success';
import Uploader from '../../../components/Forms/Uploader/Uploader';

const TreesSpeciesList = () => {
    const { user, showLoader } = useContext(GlobalContext);
    const [ response, setResponse ] = useState(null);
    const [ species, setSpecies ] = useState([]);
    const [ selectedSpecies, selectSpecies ] = useState(null);
    const initialDataState = {
        filter: undefined,
        sort: [
            {
                field: "name",
                dir: "asc"
            }
        ]
    };
    const [ dataState, setDataState ] = useState(initialDataState);
    const [ dataResult, setDataResult ] = useState(
        process(species, dataState)
    );

    useEffect(()=> {
        if (species.length === 0) {
            fetch(process.env.REACT_APP_API_BASE_URL + "/admin/trees/species", {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    setSpecies(response.data.species);
                    setDataResult(process(response.data.species, dataState));
            });
        }
    }, [ user, species, setSpecies, dataState ])

    const dataStateChange = (e) => {
        setDataState(e.dataState);
        setDataResult(process(species, e.dataState));
    };

    const updateSpecies = (data) => {
        selectSpecies({
            ...selectedSpecies,
            photos_description: data.photos_description,
            photos_leaves: data.photos_leaves,
            photos_fruits: data.photos_fruits,
        });
        let form_data = new FormData()

        form_data.set('id', data.id);
        form_data.set('name', data.name);
        form_data.set('common_name', data.common_name);
        form_data.set('description', data.description);
        form_data.set('habitat', data.habitat);
        form_data.set('leaves', data.leaves);
        form_data.set('fruits', data.fruits);

        if (data.photos_description.length > 0) {
            for (let file of data.photos_description) {
                if (file instanceof File) {
                    form_data.append('photos_description[]', file);
                }
                else {
                    form_data.append('photos_description[]', JSON.stringify(file));
                }
            }
        }

        if (data.photos_leaves.length > 0) {
            for (let file of data.photos_leaves) {
                if (file instanceof File) {
                    form_data.append('photos_leaves[]', file);
                }
                else {
                    form_data.append('photos_leaves[]', JSON.stringify(file));
                }
            }
        }

        if (data.photos_fruits.length > 0) {
            for (let file of data.photos_fruits) {
                if (file instanceof File) {
                    form_data.append('photos_fruits[]', file);
                }
                else {
                    form_data.append('photos_fruits[]', JSON.stringify(file));
                }
            }
        }

        showLoader(true);

        fetch(process.env.REACT_APP_API_BASE_URL + '/admin/trees/species/' + selectedSpecies.id, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + user.access_token
            },
            body: form_data
        })
        .then((response) => response.json())
        .then((response) => {
            showLoader(false);
            setResponse(response.status);
            selectSpecies(response.data.species);

            let index = species.findIndex(x=> x.id === response.data.species.id);
            species[index] = response.data.species;

            setDataResult(process(species, dataState));
        })
        .catch((err) => {
        });
    }

    const SpeciesEditor = () => {
        return (
            <Form
                key={ JSON.stringify(selectedSpecies) }
                initialValues={ selectedSpecies }
                onSubmit={ updateSpecies }
                render={(formRenderProps) => (
                    <FormElement>
                        <GridLayout cols={[ { width: '1fr' }, { width: '1fr' } ]} gap={{ rows: 20, cols: 20 }}>
                            <GridLayoutItem row={ 1 } col={ 1 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Nome comune</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="common_name" component={ Input } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem row={ 1 } col={ 2 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Nome scientifico</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="name" component={ Input } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem row={ 2 } col={ 1 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Descrizione</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="description" autoSize={ true } component={ TextArea } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem row={ 2 } col={ 2 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Immagini</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="photos_description" component={ Uploader } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem row={ 3 } col={ 1 } colSpan={ 2 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Habitat</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="habitat" autoSize={ true } component={ TextArea } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem row={ 4 } col={ 1 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Foglie</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="leaves" autoSize={ true } component={ TextArea } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem row={ 5 } col={ 1 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Immagini (foglie)</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="photos_leaves" component={ Uploader } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem row={ 4 } col={ 2 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Fiori e frutti</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="fruits" autoSize={ true } component={ TextArea } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem row={ 5 } col={ 2 }>
                                <fieldset className="k-form-fieldset">
                                    <FieldWrapper>
                                        <Label className="k-form-label">Immagini (fiori e frutti)</Label>
                                        <div className="k-form-field-wrap">
                                            <Field name="photos_fruits" component={ Uploader } />
                                        </div>
                                    </FieldWrapper>
                                </fieldset>
                            </GridLayoutItem>

                            <GridLayoutItem colSpan={ 4 }>
                                <div className="k-form-buttons k-justify-content-between k-flex-wrap">
                                    <Button type="submit" rounded={ null } size="large" themeColor="primary" disabled={ !formRenderProps.modified }>SALVA</Button>
                                </div>
                            </GridLayoutItem>
                        </GridLayout>
                    </FormElement>
                )}
            />
        );
    }

    const openSpecies = (species_id) => {
        let s = species.find(x => x.id === species_id);
        selectSpecies(s);
    }

    const Actions = (props) => {
        return (
            <td>
                <Button className="buttons-container-button" icon="hyperlink-open" onClick={ () => openSpecies(props.dataItem.id) }></Button>
            </td>
        )
    }

    const Health = (props) => {
        return (
            <td>
                <div className={ 'dot bg-' + props.dataItem.health }></div>
            </td>
        )
    }

    return (
        <>
            <Title>
                Lista specie
                <small>({ species.length })</small>
                <xsmall className="k-ml-1">
                    (
                        <div className="dot bg-green k-d-inline-block"></div> { species.filter(s => s.health == 'green').length }
                        <div className="dot bg-yellow k-d-inline-block k-ml-1"></div> { species.filter(s => s.health == 'yellow').length }
                        <div className="dot bg-red k-d-inline-block k-ml-1"></div> { species.filter(s => s.health == 'red').length }
                    )
                </xsmall>
            </Title>
            <div className="section grid-template-two-columns">
                <div className="left k-pt-6">
                    <Grid
                        data={ dataResult }
                        style={{
                            height: "100%",
                        }}
                        scrollable="scrollable"
                        fixedScroll={ true }
                        sortable={ true }
                        filterable={ true }
                        { ...dataState }
                        onDataStateChange={ dataStateChange }
                    >
                        <GridColumn field="id" title="Id" width="50px" filterable={ false } />
                        <GridColumn field="name" title="Nome scientifico" />
                        <GridColumn field="common_name" title="Nome comune" />
                        <GridColumn field="trees" title="Alberi" width="80px" filterable={ false } />
                        <GridColumn field="health" title="&nbsp;" width="60px" filterable={ false } cell={ Health } />
                        <GridColumn field="actions" title="&nbsp;" width="60px" filterable={ false } cell={ Actions } />
                    </Grid>
                </div>

                <div className="right">
                    { selectedSpecies &&
                        <SpeciesEditor />
                    }
                </div>
            </div>

            <Success enter={ response } exit={ setResponse } />
        </>
    );
}

export default TreesSpeciesList;
